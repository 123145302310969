<template>
  <app-page-layout :title="$t('leave.crud.create')" icon="mdi-airplane-takeoff" @close="leavePage">
    <template #content>
      <v-window v-model="step">
        <v-window-item :value="1">
          <validation-observer ref="form">
            <v-layout row wrap>
              <v-flex xs12>
                <v-radio-group v-model="leaveRequestType" mandatory row>
                  <v-radio :label="$t('leave.daily')" value="FULL_DAY" />
                  <v-radio :label="$t('leave.hourly')" value="HOURLY" />
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex :class="leaveRequestType === 'FULL_DAY' ? 'xs6' : 'xs12'">
                <validation-provider :name="$t('leave.begin_date')" rules="required|date" v-slot="{ errors }">
                  <ab-date-picker v-model="beginDate" :label="$t('leave.begin_date')" :error-messages="errors" />
                </validation-provider>
              </v-flex>
              <v-flex xs12 sm6 v-if="leaveRequestType === 'FULL_DAY'">
                <validation-provider :name="$t('leave.end_date')" rules="required|date" v-slot="{ errors }">
                  <ab-date-picker v-model="endDate" :label="$t('leave.end_date')" :error-messages="errors" />
                </validation-provider>
              </v-flex>
            </v-layout>
            <v-layout row wrap v-if="leaveRequestType === 'HOURLY'">
              <v-flex xs6>
                <v-menu ref="menu" v-model="beginTimeMenu" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider :name="$t('leave.begin_time')" rules="required" v-slot="{ errors }">
                      <v-text-field v-model="beginTime" :label="$t('leave.begin_time')" readonly
                        :error-messages="errors" v-bind="attrs" v-on="on" />
                    </validation-provider>
                  </template>
                  <v-time-picker v-if="beginTimeMenu" v-model="beginTime" no-title full-width format="24hr"
                    @click:minute="beginTimeMenu = false" />
                </v-menu>
              </v-flex>
              <v-flex xs6>
                <v-menu ref="menu" v-model="endTimeMenu" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider :name="$t('leave.end_time')" rules="required" v-slot="{ errors }">
                      <v-text-field v-model="endTime" :label="$t('leave.end_time')" readonly :error-messages="errors"
                        v-bind="attrs" v-on="on" />
                    </validation-provider>
                  </template>
                  <v-time-picker v-if="endTimeMenu" v-model="endTime" full-width no-title format="24hr"
                    @click:minute="endTimeMenu = false" />
                </v-menu>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12>
                <validation-provider :name="$t('leave.type')" rules="required" v-slot="{ errors }">
                  <leave-type :disabled="!beginDate && !endDate" v-model="leaveAccrual" :begin-date="beginDate"
                    :leave-request-type="leaveRequestType" :error-messages="errors" return-object
                    @change="setLeaveTypeDurations" />
                </validation-provider>
              </v-flex>
              <v-flex xs12>
                <validation-provider :name="$t('global.address')" :rules="{ required: isAddressRequired, max: 512 }"
                  v-slot="{ errors }">
                  <v-textarea :label="$t('global.address')" v-model.trim="leaveRequest.address" :counter="512"
                    :error-messages="errors" rows="1" />
                </validation-provider>
              </v-flex>
              <v-flex xs12>
                <validation-provider :name="$t('global.explanation')"
                  :rules="{ required: isExplanationRequired, max: 512 }" v-slot="{ errors }">
                  <v-textarea :label="$t('global.explanation')" v-model.trim="leaveRequest.explanation" :counter="512"
                    :error-messages="errors" rows="1" />
                </validation-provider>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 v-if="isDocumentRequired && requiredDocuments.length">
                <v-subheader mt-3 class="pl-0 font-weight-bold">
                  {{ $t("leave.request.requested_leave_type_documents") }}
                  <v-divider inset></v-divider>
                </v-subheader>
                <v-timeline dense class="mb-3">
                  <v-timeline-item large v-for="(item, index) in requiredDocuments" :key="index" color="blue"
                    icon="mdi-attachment" icon-color="white">
                    <v-card elevation="1" color="grey lighten-5">
                      <v-card-title class="caption">
                        {{ item.name }}
                        <v-spacer />
                        <file-download-button :url="item.url" />
                      </v-card-title>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </v-flex>
              <v-flex xs12 class="d-flex justify-center align-center">
                <v-btn depressed color="primary" :loading="uploading" :disabled="uploading" @click="onPickFile">
                  <template v-slot:loader>
                    <span>{{ $t("global.loading") }}</span>
                    <v-progress-circular class="ml-2" indeterminate :size="22" :width="2" />
                  </template>
                  <span class="white--text">{{ $t("buttons.file_upload") }}</span>
                  <v-icon right color="white">mdi-cloud-upload</v-icon>
                </v-btn>
                <input type="file" style="display: none" ref="fileInput"
                  accept="application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf, image/*"
                  @change="onFilePicked" />
              </v-flex>
            </v-layout>
            <v-layout row wrap v-if="uploadedFiles.length">
              <v-flex xs12>
                <v-subheader class="px-0">
                  {{ $t("global.uploaded_files") }}
                  <v-divider inset />
                </v-subheader>
              </v-flex>
              <v-flex xs12>
                <v-slide-x-transition mode="out-in" class="mb-2 py-0" group tag="div">
                  <v-card v-for="(item, index) in uploadedFiles" elevation="0" color="grey lighten-4" class="mb-2"
                    :key="index">
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon>mdi-attachment</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.fileName }}</v-list-item-title>
                        <v-list-item-subtitle>{{ $helpers.humanFileSize(item.size) }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon @click="onRemoveFile(item.url, index)">
                          <v-icon :size="20">mdi-delete</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-card>
                </v-slide-x-transition>
              </v-flex>
              <v-flex style="padding-left: 20px" xs12 v-if="uploadedFiles.length > 0">
                <v-checkbox v-model="documentVisibleByHR" dense hide-details="" :label="$t('documentVisibleByHR')" />
              </v-flex>
            </v-layout>
          </validation-observer>
        </v-window-item>
        <v-window-item :value="2">
          <v-row>
            <v-col cols="12">
              <leave-request-information :leave-request-days="leaveRequest.leaveRequestDays"
                :leave-accrual="leaveAccrual" :date-range-count="dateRangeCount" :leave-request-type="leaveRequestType"
                :extra-day="leaveRequest.extraDay" :extra-minute="leaveRequest.extraMinute"
                :calculating="calculating" />
            </v-col>
            <v-col cols="12" v-for="(day, index) in leaveRequest.leaveRequestDays" :key="index">
              <leave-request-day :leaveAccrual="leaveAccrual" :leave-type-durations="leaveTypeDurations"
                v-model="leaveRequest.leaveRequestDays[index]" :index="index" editable
                :isShiftEndsBeforeNoon="isShiftEndsBeforeNoon(day.date)" />
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </template>
    <template #footer>
      <app-page-footer>
        <template #left>
          <v-btn v-if="step === 2" text @click="onClickBack">{{ $t("buttons.back") }}</v-btn>
        </template>
        <template #right>
          <v-btn v-if="step === 1" text :loading="loading" :disabled="isContinueButtonDisabled"
            @click="onClickContinue">{{ $t("buttons.continue") }}</v-btn>
          <v-btn v-if="step === 2" text :loading="loading" :disabled="calculating" @click="onSendLeaveRequest">{{
    $t("buttons.save")
            }}</v-btn>
        </template>
      </app-page-footer>
    </template>
  </app-page-layout>
</template>

<script>
import LeaveRequestDTO from "../model/LeaveRequestDTO";
import { FILTER_HOLIDAYS, FILTER_LEAVE_REQUEST, FILTER_WORKPLANS } from "../../query";
import LeaveRequestDay from "../model/LeaveRequestDay";

export default {
  name: "create-leave-request",
  data: () => ({
    documentVisibleByHR: false,
    loading: false,
    beginDateMenu: false,
    endDateMenu: false,
    beginDate: null,
    endDate: null,
    beginTimeMenu: false,
    endTimeMenu: false,
    beginTime: null,
    endTime: null,
    step: 1,
    leaveAccrual: null,
    leaveRequest: new LeaveRequestDTO(),
    holidays: [],
    workplans: [],
    workplanOffDays: [],
    workplanOvertimes: [],
    workplanBreakDays: [],
    employeeWorkplans: [],
    leaveRequests: [],
    leaveTypeDurations: [],
    dateRangeCount: 0,
    countOffday: 0,
    countAsWorkday: false,
    requestLeaveAcceptedTotalCount: 0,
    leaveRequestType: "FULL_DAY",
    calculating: false,
    oldLeaveRequestDays: null,
    uploading: false,
    selectedUploadFile: null,
    uploadedFiles: [],
    isSaved: false
  }),
  components: {
    LeaveType: () => import("../components/leave-type-picker"),
    AppPageFooter: () => import("@/layouts/app-page-footer"),
    LeaveRequestDay: () => import("../components/leave-request-day"),
    LeaveRequestItem: () => import("../components/leave-request-item"),
    LeaveRequestInformation: () => import("../components/leave-request-information")
  },
  watch: {
    leaveRequestType: {
      handler() {
        this.endDate = null;
        this.leaveRequest.leaveType = null;
        this.leaveAccrual = null;
        this.$refs.form.reset();
      },
      deep: true
    },
    leaveAccrual: {
      handler(leaveAccrual) {
        if (leaveAccrual) {
          this.countAsWorkday = this.leaveAccrual.leavePolicy.leaveType.restriction.countAsWorkday;
          const includeWeekendsAfter = this.leaveAccrual.leavePolicy.leaveType.restriction.includeWeekendsAfter || 0;
          const result = includeWeekendsAfter ? this.employeeWorkplans.length / includeWeekendsAfter : 0;
          this.leaveRequest.leaveType = leaveAccrual.leavePolicy.leaveType.id;
          this.countOffday = isNaN(result) ? 0 : parseInt(result);
        }
      },
      deep: true
    },
    beginDate: {
      handler(date) {
        if (date) {
          if (this.leaveRequestType === "HOURLY") {
            this.endDate = this.$moment(date).format("YYYY-MM-DD");
          }
          if (this.endDate) {
            this.endDate = this.$moment(date).isAfter(this.endDate) ? date : this.endDate;
            this.fetchEmployeeWorkplans();
            this.setDateRangeChange();
            this.fetchHolidays();
            this.filterLeaveRequestDays();
          }
        }
      },
      deep: true
    },
    endDate: {
      handler(date) {
        if (date) {
          if (this.beginDate) {
            this.beginDate = this.$moment(date).isBefore(this.beginDate) ? date : this.beginDate;
            this.fetchEmployeeWorkplans();
            this.setDateRangeChange();
            this.fetchHolidays();
            this.filterLeaveRequestDays();
          }
        }
      },
      deep: true
    },
    "leaveRequest.leaveRequestDays": {
      handler(leaveRequestDays) {
        if (this.oldLeaveRequestDays && JSON.stringify(leaveRequestDays) !== this.oldLeaveRequestDays) {
          if (this.leaveRequestType === "HOURLY") {
            this.calculateHourly(leaveRequestDays);
          } else {
            this.calculate(leaveRequestDays);
          }
        }
        if (leaveRequestDays.length) {
          this.oldLeaveRequestDays = JSON.stringify(leaveRequestDays);
        }
        this.requestLeaveAcceptedTotalCount = leaveRequestDays.reduce((total, item) => {
          return item.leaveRequestDayType === "ON_LEAVE" ? total + item.durationValue() : total;
        }, 0);
      },
      deep: true
    }
  },
  computed: {
    isContinueButtonDisabled() {
      return !this.documentVisibleByHR && this.uploadedFiles.length > 0;
    },
    isLanguageTurkish() {
      return this.$store.state.auth.user.language === "tr";
    },
    isAddressRequired() {
      return this.leaveAccrual && this.leaveAccrual.leavePolicy.leaveType.restriction.addressRequired;
    },
    isExplanationRequired() {
      return this.leaveAccrual && this.leaveAccrual.leavePolicy.leaveType.restriction.explanationRequired;
    },
    isDocumentRequired() {
      return this.$helpers.get(this.leaveAccrual, "leavePolicy.leaveType.restriction.documentRequired");
    },
    requiredDocuments() {
      return this.$helpers.get(this.leaveAccrual, "leavePolicy.leaveType.restriction.documents") || [];
    },
    noticePeriod() {
      return (this.leaveAccrual && this.leaveAccrual.leavePolicy.leaveType.restriction.noticePeriod) || 0;
    },
    totalMaxExceedLeaveLeaveBalance() {
      return (this.leaveAccrual && this.leaveAccrual.leavePolicy.leaveType.restriction.maxExceedLeaveBalance) || 0;
    },
    totalBalance() {
      let total = 0;
      if (this.leaveAccrual) {
        total =
          this.leaveAccrual.previousBalance + this.leaveAccrual.leavePolicy.accrualCount - this.leaveAccrual.used;
      }
      const fixedTotal = total.toFixed(2);
      return Number(fixedTotal);
    },
    includeWeekendsAfter() {
      return this.leaveAccrual ? this.leaveAccrual.leavePolicy.leaveType.restriction.includeWeekendsAfter || 0 : 0;
    }
  },
  methods: {
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(e) {
      this.selectedUploadFile = e.target.files[0];
      if (this.selectedUploadFile) {
        this.validateFile(this.selectedUploadFile)
          .then((valid) => {
            if (valid) this.uploadLeaveRequestFile(this.selectedUploadFile);
          })
          .catch(() => {
            let message = {
              text: "Geçersiz dosya formatı",
              type: "error",
              color: "error"
            };
            this.$eventBus.$emit("snack-bar-notification", message);
          });
      } else {
        this.selectedUploadFile = null;
        this.$refs.fileInput.value = null;
      }
    },
    validateFile(file) {
      return new Promise((resolve, reject) => {
        const validFormats = [
          "application/msword",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
          "application/pdf",
          "image/png",
          "image/jpg",
          "image/jpeg"
        ];
        const isFormalExtension = validFormats.includes(file.type);
        const fileSize = file.size / 1024 / 1024 < 8;
        if (!isFormalExtension) reject({ message: "file_format_error" });
        if (!fileSize) reject({ message: "less_than_2_mb_error" });
        resolve(true);
      });
    },
    uploadLeaveRequestFile(file) {
      const storageType = "LEAVE_DOCUMENT";
      const formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("storageType", storageType);
      this.uploading = true;
      this.$api.storageService
        .upload(formData, {
          onUploadProgress: (progressEvent) => { }
        })
        .then(({ data }) => {
          if (data.data) {
            this.leaveRequest.documentURLs.push(data.data);
            this.uploadedFiles.push({
              fileName: this.selectedUploadFile.name,
              size: this.selectedUploadFile.size,
              type: this.selectedUploadFile.type,
              url: data.data
            });
          }
        })
        .catch((e) => {
          let message = {
            text: "The file could not be uploaded",
            type: "error",
            color: "error"
          };
          this.$eventBus.$emit("snack-bar-notification", message);
        })
        .then(() => {
          this.$refs.fileInput.value = "";
          this.uploading = false;
          this.selectedUploadFile = null;
        });
    },
    onRemoveFile(url, index) {
      if (url) {
        const storageType = "LEAVE_DOCUMENT";
        this.$api.storageService
          .delete(storageType, url)
          .then(({ data }) => {
            this.leaveRequest.documentURLs.splice(index, 1);
            this.uploadedFiles.splice(index, 1);
          })
          .catch((e) => {
            let message = {
              text: "Dosya silinemedi",
              type: "error",
              color: "error"
            };
            this.$helpers.showNotification(message);
            // this.$eventBus.$emit("snack-bar-notification", message);
          })
          .then(() => { });
      }
    },
    removeAllUploadedFiles() {
      const storageType = "LEAVE_DOCUMENT";
      this.$api.storageService
        .multipleDelete(storageType, this.leaveRequest.documentURLs)
        .then(({ data }) => { })
        .catch((e) => {
          let message = {
            text: "Dosyalar silinemedi",
            type: "error",
            color: "error"
          };
          this.$eventBus.$emit("snack-bar-notification", message);
        })
        .then(() => { });
    },
    leavePage() {
      if (!this.isSaved && this.uploadedFiles.length) this.removeAllUploadedFiles();
      this.uploadedFiles = [];
      this.$router.replace({ name: "leave_requests" });
    },
    timeConvertToMinutes(time) {
      let hour = this.$moment(time, "HH:mm");
      return hour.hour() * 60 + hour.minute();
    },
    async fetchEmployeeWorkplans() {
      this.loading = true;
      await this.$apollo
        .query({
          query: FILTER_WORKPLANS,
          variables: {
            criteria: {
              employees: [this.$store.state.auth.user.id],
              beginDate: this.beginDate,
              endDate: this.endDate
            }
          },
          fetchPolicy: "no-cache",
          errorPolicy: "all"
        })
        .then(({ data, errors }) => {
          if (!data.error && !errors) {
            if (data.filterWorkplans) {
              this.workplans = [];
              this.workplanOffDays = [];
              this.workplanOvertimes = [];
              this.workplanBreakDays = [];
              this.employeeWorkplans = [];

              this.workplans = data.filterWorkplans.map((row) => row.date);
              data.filterWorkplans.forEach((workplan) => {
                const workDay = workplan.assignedShifts.some(
                  (shift) => shift.shiftType === "FIX" || shift.shiftType === "FLEXIBLE"
                );
                // Tüm Mesai günlerini al
                if (workDay) this.employeeWorkplans.push(workplan);
                // Sadece Dinlenme günü olanları al
                if (workplan.breakDay) this.workplanBreakDays.push(workplan.date);
                // Fazla mesai vardiyası olanları al
                if (workplan.assignedShifts) {
                  workplan.assignedShifts.forEach((shift) => {
                    if (shift.shiftType === "OVERTIME") {
                      if (!this.workplanOvertimes.includes(workplan.date)) {
                        this.workplanOvertimes.push(workplan.date);
                      }
                    }
                  });
                }
                // Hafta Tatili olanları al
                if (
                  (workplan.assignedShifts.length === 0 || workplan.assignedShifts[0].shiftType === "OVERTIME") &&
                  !workplan.breakDay
                ) {
                  this.workplanOffDays.push(workplan.date);
                }
              });
              if (this.leaveAccrual) {
                this.countAsWorkday = this.leaveAccrual.leavePolicy.leaveType.restriction.countAsWorkday;
                const includeWeekendsAfter =
                  this.leaveAccrual.leavePolicy.leaveType.restriction.includeWeekendsAfter || 0;
                const result = includeWeekendsAfter ? this.employeeWorkplans.length / includeWeekendsAfter : 0;
                this.countOffday = isNaN(result) ? 0 : parseInt(result);
              }
            }
          }
        })
        .catch((e) => this.$helpers.showNotification(e.message))
        .finally(() => (this.loading = false));
    },
    async fetchHolidays() {
      await this.$apollo
        .query({
          query: FILTER_HOLIDAYS,
          variables: {
            criteria: {
              beginDate: this.beginDate,
              endDate: this.endDate
            }
          },
          fetchPolicy: "no-cache",
          errorPolicy: "all"
        })
        .then(({ data, errors }) => {
          if (!data.error && !errors) {
            this.holidays = data.filterHolidays;
          }
        })
        .catch((e) => this.$helpers.showNotification(e.message))
        .finally(() => (this.loading = false));
    },
    async filterLeaveRequestDays() {
      this.loading = true;
      this.leaveRequestDays = [];
      await this.$apollo
        .query({
          query: FILTER_LEAVE_REQUEST,
          variables: {
            criteria: {
              beginDate: this.beginDate,
              endDate: this.endDate,
              employees: [this.$store.state.auth.user.id],
              leaveRequestStatuses: ["APPROVED", "PENDING"]
            }
          },
          fetchPolicy: "no-cache",
          errorPolicy: "all"
        })
        .then(({ data, errors }) => {
          if (!data.error && !errors) {
            this.leaveRequests = [];
            data.filterLeaveRequests.forEach((item) => {
              item.leaveRequestDays.forEach((row) => this.leaveRequests.push(row));
            });
          }
        })
        .catch((e) => {
          console.log("e", e);
        })
        .then(() => {
          this.loading = false;
        });
    },
    offDaysAndHolidaysCriteria(date) {
      if (date) {
        const dayCount = this.leaveAccrual.leavePolicy.leaveType.restriction.countAsWorkday
          ? this.employeeWorkplans.length
          : this.dateRangeCount;
        let leaveRequestDayType = "ON_LEAVE";
        let includeWeekendsAfter = this.leaveAccrual.leavePolicy.leaveType.restriction.includeWeekendsAfter;
        let includeHolidaysAfter = this.leaveAccrual.leavePolicy.leaveType.restriction.includeHolidaysAfter;
        let includeBreakDaysAfter = this.leaveAccrual.leavePolicy.leaveType.restriction.includeBreakDaysAfter;
        let hasHoliday = this.holidays.find((item) => item.date === date);
        let hasWorkplanInDate = this.workplans.includes(date);
        if (hasWorkplanInDate) {
          let hasOffDay = this.workplanOffDays.includes(date);
          let hasOvertimeDay = this.workplanOvertimes.includes(date);
          let hasBreakDays = this.workplanBreakDays.includes(date);
          if (hasHoliday) {
            leaveRequestDayType = "HOLIDAY";
            // kacıncı günden sonra hafta tatilleri izinden sayılacak
            if (
              (includeHolidaysAfter !== null && includeHolidaysAfter > 0 && dayCount >= includeHolidaysAfter) ||
              hasHoliday.halfDay
            ) {
              leaveRequestDayType = "ON_LEAVE";
            }
          } else if (hasBreakDays) {
            // Kaçıncı günden sonra dinlenme günleri izinden sayılacak
            leaveRequestDayType = "BREAK";
            if (includeBreakDaysAfter !== null && includeBreakDaysAfter > 0 && dayCount >= includeBreakDaysAfter) {
              leaveRequestDayType = "ON_LEAVE";
            }
          } else if (hasOffDay) {
            leaveRequestDayType = "OFFDAY";
            // kacıncı günden sonra hafta tatilleri izinden sayılacak
          } else if (hasOvertimeDay) {
            leaveRequestDayType = "OFFDAY";
            // kacıncı günden sonra fazla mesai günleri izinden sayılacak
            if (includeWeekendsAfter !== null && includeWeekendsAfter > 0 && dayCount >= includeWeekendsAfter) {
              leaveRequestDayType = "ON_LEAVE";
            }
          }
        } else {
          if (hasHoliday) {
            leaveRequestDayType = "HOLIDAY";
            // kacıncı günden sonra resmi tatiller izinden sayılacak
            if (
              (includeHolidaysAfter !== null && includeHolidaysAfter > 0 && dayCount >= includeHolidaysAfter) ||
              hasHoliday.halfDay
            ) {
              leaveRequestDayType = "ON_LEAVE";
            }
          }
        }
        return leaveRequestDayType;
      }
    },
    setDateRangeChange() {
      if (!this.beginDate || !this.endDate) return false;
      let newStartDate = this.$moment(this.beginDate);
      let newEndDate = this.$moment(this.endDate);
      let newDateDiffCount = this.$moment.duration(newEndDate.diff(newStartDate));
      this.dateRangeCount = newDateDiffCount.asDays() + 1;
    },
    setLeaveTypeDurations() {
      let durations = this.leaveAccrual ? this.leaveAccrual.leavePolicy.leaveType.restriction.durations.sort() : [];
      let leaveTypeDurations = durations.filter((item) => item !== "HOURLY");
      this.leaveTypeDurations = leaveTypeDurations.map((item) => {
        return {
          text: this.$t(`leave_duration.${item}`, []),
          value: item
        };
      });
    },
    noticePeriodCriteria() {
      return new Promise((resolve, reject) => {
        if (this.noticePeriod) {
          let nowDate = this.$moment().format("YYYY-MM-DD");
          if (this.beginDate >= nowDate) {
            let noticePeriodBeginDate = this.$moment(this.beginDate)
              .subtract(this.noticePeriod, "days")
              .format("YYYY-MM-DD");
            if (noticePeriodBeginDate < nowDate)
              reject(this.$t("leave.error_messages.notice_period_error", [this.noticePeriod]));
          }
        }
        resolve(true);
      });
    },
    maxConsecutiveCriteria() {
      let minConsecutive = this.leaveAccrual && this.leaveAccrual.leavePolicy.leaveType.restriction.minConsecutive;
      let maxConsecutive = this.leaveAccrual && this.leaveAccrual.leavePolicy.leaveType.restriction.maxConsecutive;
      return new Promise((resolve, reject) => {
        if (maxConsecutive && this.dateRangeCount > maxConsecutive) {
          reject(this.$t("leave.error_messages.max_consecutive_error", [maxConsecutive]));
        } else if (minConsecutive && this.dateRangeCount < minConsecutive) {
          reject(this.$t("leave.error_messages.min_consecutive_error", [minConsecutive]));
        } else {
          resolve(true);
        }
      });
    },
    isBetweenLeaveRequestDayTimes(day) {
      let hasFindItem = false;
      let selectedBeginDateTime = this.$moment(`${this.beginDate} ${this.beginTime}`);
      let selectedEndDateTime = this.$moment(`${this.beginDate} ${this.endTime}`);
      let beginDayFormat = this.$moment(`${day.date} ${day.fromTime}`);
      let endDayFormat = this.$moment(`${day.date} ${day.fromTime}`).add(day.lengthInMinutes, "minutes");
      // İzin başlangıc tarihi talep edilen tarihler arasına denk geliyor mu?
      let isLeaveBeginDateTimeIsBetween = beginDayFormat.isBetween(
        selectedBeginDateTime.format("YYYY-MM-DD HH:mm"),
        selectedEndDateTime.format("YYYY-MM-DD HH:mm"),
        null,
        "[)"
      );
      // izin bitiş tarihi talep edilen tarihler arasına denk geliyor mu?
      let isLeaveEndDateTimeIsBetween = endDayFormat.isBetween(
        selectedBeginDateTime.format("YYYY-MM-DD HH:mm"),
        selectedEndDateTime.format("YYYY-MM-DD HH:mm"),
        "(]"
      );
      // Talep Edilen başlangıç tarihi varolan izin saatleri arasına denk geliyor mu?
      let isBeginDateTimeIsBetween = selectedBeginDateTime.isBetween(
        beginDayFormat.format("YYYY-MM-DD HH:mm"),
        endDayFormat.format("YYYY-MM-DD HH:mm")
      );
      // Talep Edilen bitiş tarihi varolan izin saatleri arasına denk geliyor mu?
      let isEndDateTimeIsBetween = selectedEndDateTime.isBetween(
        beginDayFormat.format("YYYY-MM-DD HH:mm"),
        endDayFormat.format("YYYY-MM-DD HH:mm")
      );
      hasFindItem =
        isBeginDateTimeIsBetween ||
        isEndDateTimeIsBetween ||
        isLeaveBeginDateTimeIsBetween ||
        isLeaveEndDateTimeIsBetween;
      return hasFindItem;
    },
    leaveRequestExists() {
      return new Promise((resolve, reject) => {
        if (!this.leaveRequestType === "HOURLY" && this.leaveRequests.length) {
          reject(this.$t("leave.error_messages.leave_request_exist_error"));
        } else {
          let isExistsLeave = false;
          for (let i = 0; i < this.leaveRequests.length; i++) {
            let current = this.leaveRequests[i];
            isExistsLeave = this.isBetweenLeaveRequestDayTimes(current);
            if (isExistsLeave) break;
          }
          if (isExistsLeave) {
            reject(this.$t("leave.error_messages.leave_request_exist_error"));
          }
        }
        resolve(true);
      });
    },
    isShiftEndsBeforeNoon(date) {
      const workplan = this.employeeWorkplans.find(workplan => workplan.date === date);

      if (!workplan || workplan.assignedShifts.length === 0) {
        return false;
      }

      const endTime = workplan.assignedShifts[0].endTime;

      return this.$moment(endTime, "HH:mm").isBefore(this.$moment("12:00", "HH:mm"));
    },
    firstLeaveTypeDuration(date) {
      if (!Array.isArray(this.leaveTypeDurations)) return null;
      const holiday = this.holidays.find((item) => item.date === date);

      if (holiday && holiday.halfDay && !this.isShiftEndsBeforeNoon(date)) {
        return "HALF_DAY";
      }

      return this.leaveTypeDurations[0].value;
    },

    documentRequiredCriteria() {
      return new Promise((resolve, reject) => {
        if (this.isDocumentRequired && this.uploadedFiles.length < this.requiredDocuments.length) {
          reject(
            this.$tc(
              "leave.request.required_document_message",
              this.requiredDocuments.length - this.uploadedFiles.length,
              { count: this.requiredDocuments.length - this.uploadedFiles.length }
            )
          );
        }
        resolve(true);
      });
    },
    validateAll() {
      return Promise.all([
        this.leaveRequestExists(),
        this.noticePeriodCriteria(),
        this.maxConsecutiveCriteria(),
        this.totalLeaveBalance(),
        this.documentRequiredCriteria()
      ]);
    },
    calculate(leaveRequestDays) {
      this.calculating = true;
      const payload = {
        employee: this.$store.state.auth.user.id,
        leaveType: this.$helpers.get(this.leaveAccrual, "leavePolicy.leaveType.id"),
        leaveRequestDays
      };
      this.$api.leaveRequestService
        .calculate(payload)
        .then((response) => {
          if (response.status === 201) {
            this.leaveRequest.extraDay = response.data.data.totalExtraCount;
          }
        })
        .catch((error) => this.$helpers.showNotification(error.message))
        .finally(() => (this.calculating = false));
    },
    calculateHourly(leaveRequestDays) {
      this.calculating = true;
      const payload = {
        employee: this.$store.state.auth.user.id,
        leaveType: this.$helpers.get(this.leaveAccrual, "leavePolicy.leaveType.id"),
        leaveRequestDays
      };
      this.$api.leaveRequestService
        .calculateHourly(payload)
        .then((response) => {
          if (response.status === 201) {
            this.leaveRequest.extraMinute = response.data.data.totalExtraMinute;
          }
        })
        .catch((error) => this.$helpers.showNotification(error.message))
        .finally(() => (this.calculating = false));
    },
    onClickContinue() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          this.validateAll()
            .then((valids) => {
              if (!valids.some((item) => item === false)) {
                if (this.leaveRequestType === "HOURLY") {
                  let fromTimeMinutes = this.timeConvertToMinutes(this.beginTime);
                  let toTimeMinutes = this.timeConvertToMinutes(this.endTime);
                  let lengthInMinutes = toTimeMinutes - fromTimeMinutes;
                  let leaveRequestDayType = this.offDaysAndHolidaysCriteria(this.beginDate);
                  let newRequestDay = new LeaveRequestDay();
                  newRequestDay.date = this.beginDate;
                  newRequestDay.duration = "HOURLY";
                  newRequestDay.leaveRequestDayType = leaveRequestDayType;
                  newRequestDay.fromTime = this.beginTime;
                  newRequestDay.lengthInMinutes = lengthInMinutes;
                  this.leaveRequest.leaveRequestDays.push(newRequestDay);
                } else {
                  for (let i = 0; i < this.dateRangeCount; i++) {
                    let currentDay = this.$moment(this.beginDate).add(i, "days").format("YYYY-MM-DD");
                    let leaveRequestDayType = this.offDaysAndHolidaysCriteria(currentDay);
                    let newRequestDay = new LeaveRequestDay();
                    newRequestDay.date = this.$moment(this.beginDate).add(i, "days").format("YYYY-MM-DD");
                    newRequestDay.duration = this.firstLeaveTypeDuration(currentDay);
                    newRequestDay.leaveRequestDayType = leaveRequestDayType;
                    newRequestDay.holiday = this.holidays.map((item) => item.date).includes(currentDay);
                    newRequestDay.weight = this.leaveAccrual.leavePolicy.leaveType.weight || 1;
                    this.leaveRequest.leaveRequestDays.push(newRequestDay);
                  }
                }
                this.step = 2;
              }
            })
            .catch((e) => {
              this.$helpers.showNotification(e.toString(), "error", true);
            });
        }
      });
    },
    onClickNextStep() {
      this.step = 3;
    },
    totalLeaveBalance() {
      let maxExceedLeaveBalance = this.leaveAccrual.leavePolicy.leaveType.restriction.maxExceedLeaveBalance;
      const totalBalanceDiffLeaveRequestBalance = Number((this.totalBalance - this.dateRangeCount).toFixed(2));

      return new Promise((resolve, reject) => {
        if (totalBalanceDiffLeaveRequestBalance && totalBalanceDiffLeaveRequestBalance <= 0) {
          if (maxExceedLeaveBalance === null) {
            reject(this.$t("leave.error_messages.total_leave_balance_error1"));
          } else {
            // Eğer izin bakiyesi aşabilirse ve seçilen tarihlerde izinden sayılacak günlerin total balance izin bakiyesi aşabilir değeri ile toplandığı zaman 0 a eşit ya da büyükse izni talep etme hakkı doğar
            if (totalBalanceDiffLeaveRequestBalance + maxExceedLeaveBalance >= 0) {
              resolve(true);
            } else {
              reject(this.$t("leave.error_messages.total_leave_balance_error2", [maxExceedLeaveBalance]));
            }
          }
        } else {
          resolve(true);
        }
      });
    },
    onClickBack() {
      this.step = 1;
      this.leaveRequest.leaveRequestDays = [];
      this.oldLeaveRequestDays = null;
      this.leaveRequest.extraDay = 0;
      this.leaveRequest.leaveType = this.$helpers.get(this.leaveAccrual, "leavePolicy.leaveType.id");
    },
    onSendLeaveRequest() {
      this.loading = true;
      this.$api.leaveRequestService
        .save(this.leaveRequest)
        .then(({ data }) => {
          if (!data.error) {
            this.isSaved = true;
            this.$emit("success:save");
            this.leavePage();
          } else if (data.error.code === 756) {
            this.$helpers.showNotification(this.$t("api_error.756"), "error", true);
          }
        })
        .catch(console.error)
        .finally(() => (this.loading = false));
    }
  }
};
</script>
