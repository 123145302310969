import moment from "@/plugins/moment";

class LeaveRequestDay {
  constructor(
    date = null,
    duration = null,
    period = null,
    leaveRequestDayType = null,
    fromTime = null,
    lengthInMinutes = null
  ) {
    this.date = date;
    this.duration = duration;
    this.period = period;
    this.leaveRequestDayType = leaveRequestDayType;
    this.fromTime = fromTime;
    this.lengthInMinutes = lengthInMinutes;
    this.includeSaturday = false;
    this.holiday = false;
    this.weight = 1;
  }

  durationValue() {
    let value = 0;
    switch (this.duration) {
      case "FULL_DAY":
        value = this.weight;
        break;
      case "HALF_DAY":
        value = this.weight / 2;
        break;
      case "QUARTER_DAY":
        value = this.weight / 4;
        break;
      case "HOURLY":
        let now = moment().format("YYYY-MM-DD");
        let beginTime = moment(`${now} ${this.fromTime}`, "YYYY-MM-DD HH:mm");
        let endTime = moment(`${now} ${this.fromTime}`, "YYYY-MM-DD HH:mm").add(this.lengthInMinutes, "minutes");
        value = endTime.diff(beginTime, "minutes", true);
        value = moment
          .utc()
          .startOf("day")
          .add({ minutes: value })
          .format("H:mm");
        break;
    }
    return value;
  }
}

export default LeaveRequestDay;
